/* stylelint-disable */
html,
body,
#root {
  height: auto;
  min-height: 100%;
  width: 100%;
  position: relative;
}
/* stylelint-enable */

@font-face {
  font-family: 'Super Mario Bros. 2';
  font-style: normal;
  font-weight: 900;
  src: local('Super Mario Bros. 2'), url('./assets/fonts/Super Mario Bros. 2.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans'), url('./assets/fonts/OpenSans-Light.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), url('./assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Open Sans'), url('./assets/fonts/OpenSans-Medium.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans'), url('./assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans'), url('./assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans'), url('./assets/fonts/OpenSans-ExtraBold.ttf') format('truetype');
  font-display: swap;
}
